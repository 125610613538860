<template>
  <footer v-if="this.$route.name === 'WizardPage'">
      <div>
          <div class="sub-footer pt-4 pb-4 bord-thin-top ontop">
              <div class="container">
                  <div class="row">
                      <div class="col-lg-6">
                          <div class="logo d-inline-block">
                              <img src="assets/imgs/logo.svg" alt="" />
                          </div>
                          <div class="copyright d-inline-block">
                              <div class="ml-auto">
                                  <small>
                                      © {{ getCurrentYear() }} جميع الحقوق محفوظة لدى شركة الفا لتأجير السيارات
                                  </small>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-6">
                          <div class="ml-auto text-left pt-2">
                              <small>
                                  أرقام المساعدة : 
                                  [
                                  <a class="d-inline-block en btn btn-link" target="_blank" href="https://wa.me/966557666154">
                                      +966 557666154
                                  </a>
                                  |
                                  <a class="d-inline-block en btn btn-link" target="_blank" href="tel:+966920011646">
                                      +966 920011646
                                  </a>
                                  ]
                              </small>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </footer>
  <footer v-else class="sub-bg">
      <div>
          <section class="section-padding" data-scroll-index="7">
              <div class="contact-container">
                  <div class="container">
                      <div class="row pt-4">
                          <div class="col-lg-5 pt-4">
                              <div class="sec-lg-head md-mb80">
                                  <p class="fz-15 mt-10 wow fadeIn">
                                      هل تواجه مشكلة أو لديك استفسار لا تتردد في
                                  </p>
                                  <h2 class="fz-50 d-rotate wow">
                                      <span class="rotate-text">
                                          تواصل معنا
                                      </span>
                                  </h2>
                                  <p class="fz-15 mt-10 wow fadeIn">
                                      فريقنا مستعد لخدمتك بكل اهتمام واحترافية. لحل جميع تساؤلاتك وضمان رضاك الكامل.
                                  </p>
                              </div>
                          </div>
                          <div class="col-lg-2"></div>
                          <div class="col-lg-4 offset-lg-1 valign">
                              <div class="full-width">
                                  <div class="phone fz-30 fw-600 mt-10 underline wow fadeIn text-start">
                                      <p class="fz-15 mb-10 wow fadeIn text-end">
                                          رقم الجوال
                                      </p>
                                      <a href="https://wa.me/966557666154" target="_blank" class="en">
                                          +966 557666154
                                      </a>
                                  </div>
                                  <div class="phone fz-30 fw-600 mt-10 underline wow fadeIn text-start">
                                      <p class="fz-15 mb-10 wow fadeIn text-end">
                                          الاتصال الهاتفي
                                      </p>
                                      <a href="tel:+966920011646" target="_blank" class="en">
                                          +966 920011646
                                      </a>
                                  </div>
                                  <div class="phone fz-30 fw-600 mt-10 underline wow fadeIn text-start">
                                      <p class="fz-15 mb-10 wow fadeIn text-end">
                                          البريد الإلكتروني
                                      </p>
                                      <a href="mailto:info@alfao.com.sa" class="en">
                                          info@alfao.com.sa
                                      </a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <div class="sub-footer pt-40 pb-40 bord-thin-top ontop">
              <div class="container">
                  <div class="row">
                      <div class="col-lg-8 col-12">
                          <div class="logo d-inline-block">
                              <img src="assets/imgs/logo.svg" alt="" />
                          </div>
                          <div class="copyright d-inline-block">
                              <div class="ml-auto">
                                  <p>
                                      © {{ getCurrentYear() }} جميع الحقوق محفوظة لدى شركة الفا لتأجير السيارات
                                  </p>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-4 col-12">
                          <div class="underline d-inline-block mx-2">
                              <a href="http://admin.alfao.com.sa/" target="_blank">
                                  لوحة الإدارة
                              </a>
                          </div>
                          <div class="underline d-inline-block mx-2">
                              <a href="https://www.zoho.com/mail/login.html" target="_blank">
                                  لوحة الموظف
                              </a>
                          </div>
                          <div class="underline d-inline-block mx-2">
                              <a href="http://app.alfao.com.sa/" target="_blank">
                                  نظام تواصل
                              </a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="fixed-btn mobile">
          <a href="/reant" type="button" class="btn btn-primary">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M13.922 4.5V11.8125C13.922 11.9244 13.8776 12.0317 13.7985 12.1108C13.7193 12.1899 13.612 12.2344 13.5002 12.2344C13.3883 12.2344 13.281 12.1899 13.2018 12.1108C13.1227 12.0317 13.0783 11.9244 13.0783 11.8125V5.51953L4.79547 13.7953C4.71715 13.8736 4.61092 13.9176 4.50015 13.9176C4.38939 13.9176 4.28316 13.8736 4.20484 13.7953C4.12652 13.717 4.08252 13.6108 4.08252 13.5C4.08252 13.3892 4.12652 13.283 4.20484 13.2047L12.4806 4.92188H6.18765C6.07577 4.92188 5.96846 4.87743 5.88934 4.79831C5.81023 4.71919 5.76578 4.61189 5.76578 4.5C5.76578 4.38811 5.81023 4.28081 5.88934 4.20169C5.96846 4.12257 6.07577 4.07813 6.18765 4.07812H13.5002C13.612 4.07813 13.7193 4.12257 13.7985 4.20169C13.8776 4.28081 13.922 4.38811 13.922 4.5Z"
                      fill="currentColor"
                  ></path>
              </svg>
              لحجز سيارة
          </a>
      </div>
  </footer>
</template>

<script>
  export default {
      name: "FooterPage",
      props: {},
      methods: {
          getCurrentYear() {
              return new Date().getFullYear();
          },
      },
  };
</script>
