<template>

  <!-- BODY -->
            <header class="header-main overflow-hidden full-height valign bg-img parallaxie" data-background="assets/imgs/background/14.jpg" data-overlay-dark="7">
                <div class="container ontop">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="caption">
                                <h5 class="fw-300 mb-15">
                                  مرحبًا بكم في موقع الفاو لتأجير السيارات
                                </h5>
                                <h1 class="fz-80">
                                  نحن هنا لنجعل تجربتك أكثر يسرًا ومتعة
                                </h1>
                            </div>
                        </div>

                        <div class="col-lg-4 d-flex align-items-end justify-content-end justify-end en">
                            <div class="d-flex align-items-center">
                                <div>
                                    <a href="/reant" class="hover-this">
                                        <div class="circle-button hover-anim">
                                            <div class="rotate-circle fz-30 text-u">
                                                <svg class="textcircle" viewBox="0 0 500 500">
                                                    <defs>
                                                        <path id="textcircle" d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"></path>
                                                    </defs>
                                                    <text>
                                                        <textPath xlink:href="#textcircle" textLength="900">
                                                          الفاو لتأجير السيارات
                                                        </textPath>
                                                    </text>
                                                </svg>
                                            </div>
                                            <div class="arrow">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M13.922 4.5V11.8125C13.922 11.9244 13.8776 12.0317 13.7985 12.1108C13.7193 12.1899 13.612 12.2344 13.5002 12.2344C13.3883 12.2344 13.281 12.1899 13.2018 12.1108C13.1227 12.0317 13.0783 11.9244 13.0783 11.8125V5.51953L4.79547 13.7953C4.71715 13.8736 4.61092 13.9176 4.50015 13.9176C4.38939 13.9176 4.28316 13.8736 4.20484 13.7953C4.12652 13.717 4.08252 13.6108 4.08252 13.5C4.08252 13.3892 4.12652 13.283 4.20484 13.2047L12.4806 4.92188H6.18765C6.07577 4.92188 5.96846 4.87743 5.88934 4.79831C5.81023 4.71919 5.76578 4.61189 5.76578 4.5C5.76578 4.38811 5.81023 4.28081 5.88934 4.20169C5.96846 4.12257 6.07577 4.07813 6.18765 4.07812H13.5002C13.612 4.07813 13.7193 4.12257 13.7985 4.20169C13.8776 4.28081 13.922 4.38811 13.922 4.5Z"
                                                        fill="currentColor"
                                                    ></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div>
                                    <h6 class="sub-title">
                                      احجز سيارتك
                                      <br>
                                      ببضع خطوات بسيطة
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="video-background">
                    <span>
                        <video muted="" autoplay="" playsinline="" loop="" poster="">
                            <source src="assets/imgs/video.mp4" />
                        </video>
                    </span>
                </div>
            </header>
            <!-- ==================== # ==================== -->
            <section class="serv-marq main-colorbg2">
                <div class="container-fluid ontop sub-bg rest pt-20 pb-20 en">
                    <div class="row">
                        <div class="col-12">
                            <div class="main-marq light-text">
                                <div class="slide-har st1">
                                    <div class="box non-strok">
                                        <div class="item">
                                            <h4 class="d-flex align-items-center">
                                              <span>لدينا فروع متعددة</span>
                                              <span class="fz-50 ml-50 stroke icon px-4">*</span>
                                            </h4>
                                        </div>
                                        <div class="item">
                                            <h4 class="d-flex align-items-center">
                                              <span>مراكز الصيانة المتخصصة</span>
                                              <span class="fz-50 ml-50 stroke icon px-4">*</span>
                                            </h4>
                                        </div>
                                        <div class="item">
                                            <h4 class="d-flex align-items-center">
                                              <span>فريق العمل ذو الكفاءة العالية</span>
                                              <span class="fz-50 ml-50 stroke icon px-4">*</span>
                                            </h4>
                                        </div>
                                        <div class="item">
                                            <h4 class="d-flex align-items-center">
                                              <span>خدمة الوصول السريع</span>
                                              <span class="fz-50 ml-50 stroke icon px-4">*</span>
                                            </h4>
                                        </div>
                                        <div class="item">
                                            <h4 class="d-flex align-items-center">
                                              <span>اختيار مكان تسليم السيارة</span>
                                              <span class="fz-50 ml-50 stroke icon px-4">*</span>
                                            </h4>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- ==================== # ==================== -->
            <section class="about section-padding main-bg">
                <div class="container ontop">
                    <div class="row">
                        <div class="col-lg-5 valign en">
                            <div class="about-circle-crev md-hide ">
                                <div class="circle-button">
                                    <div class="rotate-circle fz-16 ls1 text-u">
                                        <svg class="textcircle" viewBox="0 0 500 500">
                                            <defs>
                                                <path id="textcircle"
                                                    d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z">
                                                </path>
                                            </defs>
                                            <text>
                                                <textPath xlink:href="#textcircle" textLength="900">
                                                  شركة الفاو لتأجير السيارات
                                                </textPath>
                                            </text>
                                        </svg>
                                    </div>
                                </div>
                                <div class="half-circle-img">
                                    <img src="assets/imgs/about_us.jpg" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-7 valign">
                            <div class="cont sec-lg-head">
                                <h6 class="dot-titl mb-20">
                                  شركة الفاو لتأجير السيارات
                                </h6>
                                <h2 class="d-slideup wow">
                                    <span class="sideup-text">
                                      <span class="up-text">
                                        نعتبر من الرواد في مجال توفير وسائل النقل بتميّز وجودة عالية
                                      </span>
                                    </span>
                                </h2>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="text mt-20">
                                            <p>
                                              تأسست شركة الفاو لتأجير السيارات في عام 1990م، ومنذ ذلك الحين أصبحت من بين الشركات الرائدة في مجالها في المنطقة الغربية للمملكة العربية السعودية. تعتبر الفاو نموذجًا للتميز في تقديم خدمات تأجير السيارات، حيث تمتلك عشرات الفروع المنتشرة في مدن مكة المكرمة، وجدة، والطائف.
                                              <br>
                                              تتجاوز الفاو دور تأجير السيارات العادي، إذ تسعى دائمًا لتحقيق رضا العملاء في المنطقة الغربية من المملكة. يتمثل التميز في تقديم خدمات الصيانة المتميزة من خلال مراكز الصيانة الموجودة في جميع الفروع، بالإضافة إلى توفير خدمة بيع السيارات المستعملة بشكل دوري.
                                              <br>
                                              نحن نفتخر بأسطولنا المتنوع والحديث الذي يلبي متطلبات جميع أنواع الرحلات، سواء كانت للأعمال أو للترفيه. تعكس الفاو رؤية قوية لتحسين تجربة التنقل في المنطقة، وذلك من خلال توفير سيارات ذات جودة عالية وخدمة عملاء فائقة.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row md-marg mt-100 justify-content-center">
                        <div class="col-lg-4 col-md-6">
                            <div class="item-serv md-mb50">
                                <div class="d-flex align-items-center pb-20 mb-30 bord-thin-bottom">
                                    <div class="mr-30">
                                        <div class="icon-img-50">
                                            <i class="fa fa-bandcamp" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <h4>
                                          رؤيتنا
                                        </h4>
                                    </div>
                                </div>
                                <p class="">
                                  نطمح إلى أن نكون روّادًا في مجال تأجير السيارات في المملكة العربية السعودية، حيث نسعى جاهدين للحصول على رضا جميع عملائنا.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="item-serv md-mb50">
                                <div class="d-flex align-items-center pb-20 mb-30 bord-thin-bottom">
                                    <div class="mr-30">
                                        <div class="icon-img-50">
                                            <i class="fa fa-superpowers" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <h4>
                                          رسالتنا
                                        </h4>
                                    </div>
                                </div>
                                <p class="">
                                  نسعى جاهدين لتلبية احتياجات عملائنا، مع الالتزام بأعلى معايير الجودة والسلامة.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="item-serv">
                                <div class="d-flex align-items-center pb-20 mb-30 bord-thin-bottom">
                                    <div class="mr-30">
                                        <div class="icon-img-50">
                                            <i class="fa fa-clock-o" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <h4>
                                          خدمتنا متاحة 24/12
                                        </h4>
                                    </div>
                                </div>
                                <p class="">
                                  يمكنكم التواصل معنا في أي وقت خلال ساعات الدوام الرسمية في أي فرع من فروعنا، حيث نضع راحتكم واحتياجاتكم في المقدمة لتقديم خدمة لا مثيل لها.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- ==================== # ==================== -->
            <div class="brand-sec en overflow-hidden position-relative sub-bg">
                <div class="container">
                    <div class="sec-lg-head text-center mb-80">
                        <h6 class="dot-titl-non mb-15 wow fadeIn">
                          أسطولنا
                        </h6>
                        <h2 class="d-rotate wow">
                            <span class="rotate-text">
                              لدينا في الفاو لتأجير السيارات تشكيلة واسعة من السيارات تتنوع بين الفئات الاقتصادية والعائلية والمتوسطة والتنفيذية، وفيما يلي بعض الماركات المتاحة لدينا.
                            </span>
                        </h2>
                    </div>
                </div>
                <div class="brand-marq main-marq">
                    <div class="slide-har st1">
                        <div class="box">
                            <div class="item">
                                <a href="#0" class="img">
                                    <img src="assets/imgs/brands/audi.png" alt="audi">
                                </a>
                            </div>
                            <div class="item">
                                <a href="#0" class="img">
                                    <img src="assets/imgs/brands/chevrolet.png" alt="chevrolet">
                                </a>
                            </div>
                            <div class="item">
                                <a href="#0" class="img">
                                    <img src="assets/imgs/brands/ford.png" alt="ford">
                                </a>
                            </div>
                            <div class="item">
                                <a href="#0" class="img">
                                    <img src="assets/imgs/brands/honda.png" alt="honda">
                                </a>
                            </div>
                            <div class="item">
                                <a href="#0" class="img">
                                    <img src="assets/imgs/brands/hyundai.png" alt="hyundai">
                                </a>
                            </div>
                            <div class="item">
                                <a href="#0" class="img">
                                    <img src="assets/imgs/brands/isuzu.png" alt="isuzu">
                                </a>
                            </div>
                            <div class="item">
                                <a href="#0" class="img">
                                    <img src="assets/imgs/brands/kia.png" alt="kia">
                                </a>
                            </div>
                            <div class="item">
                                <a href="#0" class="img">
                                    <img src="assets/imgs/brands/lexus.png" alt="lexus">
                                </a>
                            </div>
                            <div class="item">
                                <a href="#0" class="img">
                                    <img src="assets/imgs/brands/mazda.png" alt="mazda">
                                </a>
                            </div>
                            <div class="item">
                                <a href="#0" class="img">
                                    <img src="assets/imgs/brands/mercedes.png" alt="mercedes">
                                </a>
                            </div>
                        </div>
                        <div class="box">
                            <div class="item">
                                <a href="#0" class="img">
                                    <img src="assets/imgs/brands/mitsubishi.png" alt="mitsubishi">
                                </a>
                            </div>
                            <div class="item">
                                <a href="#0" class="img">
                                    <img src="assets/imgs/brands/nissan.png" alt="nissan">
                                </a>
                            </div>
                            <div class="item">
                                <a href="#0" class="img">
                                    <img src="assets/imgs/brands/suzuki.png" alt="suzuki">
                                </a>
                            </div>
                            <div class="item">
                                <a href="#0" class="img">
                                    <img src="assets/imgs/brands/toyota.png" alt="toyota">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="bg-pattern "></div>
            </div>
            <!-- ==================== # ==================== -->
            <section class="portfolio section-padding">
                <div class="container">
                    <div class="sec-lg-head mb-80">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="position-re">
                                    <h6 class="dot-titl colorbg-3 mb-10">
                                      في المنطقة الغربية
                                    </h6>
                                    <h2 class="fz-60 fw-700">
                                      نحن قريبين منك
                                    </h2>
                                </div>
                            </div>
                            <div class="col-lg-4 d-flex align-items-center">
                                <div class="text">
                                    <p>
                                      بحث عن أقرب فرع لنا، وستكون خدماتنا متاحة لك في كل مكان تتجه إليه.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="work-row">
                        <!-- ********************-->
                        <div 
                        class="item bord-thin-top"
                        v-for="(item, index) in SQLBranches"
                        :key="item.id"
                        >
                            <div class="row">
                                <div class="col-lg-3 d-flex align-items-end">
                                    <div class="title">
                                        <span class="numb">{{  index + 1  }}.</span>
                                        <h6 class="m-0 p-0">
                                          [{{  item.city }}]
                                        </h6>
                                        <h5>
                                            {{  item.name }}
                                        </h5>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="img md-mb30">
                                      <img :src="item.poster" :alt="item.name" />
                                    </div>
                                </div>
                                <div class="col-lg-5 d-flex align-items-end">
                                    <div class="cont">
                                        <small class="lh-base">
                                            <div v-if="item.address">
                                                <span class="fw-bolder d-block fw-700 mt-2">[العنوان]</span>
                                                <span>
                                                    {{  item.address }}
                                                </span>
                                            </div>

                                            <div v-if="item.tell">
                                                <br>
                                                <span class="fw-bolder d-block fw-700 mt-2">[رقم التواصل]</span>
                                                <span class="en">
                                                    {{  item.tell }}
                                                </span>
                                            </div>

                                           <div v-if="item.working_time">
                                                <br>
                                                <span class="fw-bolder d-block fw-700 mt-2">[أوقات الدوام]</span>
                                                {{  item.working_time }}
                                            </div>

                                        </small>
                                        <a 
                                            :href="item.location"
                                            target="_blank" 
                                            class="mt-30 ls1-x sub-title btn btn-outline-dark  "
                                            v-if="item.location"
                                        >
                                            إذهب للخريطة
                                            <i class="ml-5">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M13.922 4.5V11.8125C13.922 11.9244 13.8776 12.0317 13.7985 12.1108C13.7193 12.1899 13.612 12.2344 13.5002 12.2344C13.3883 12.2344 13.281 12.1899 13.2018 12.1108C13.1227 12.0317 13.0783 11.9244 13.0783 11.8125V5.51953L4.79547 13.7953C4.71715 13.8736 4.61092 13.9176 4.50015 13.9176C4.38939 13.9176 4.28316 13.8736 4.20484 13.7953C4.12652 13.717 4.08252 13.6108 4.08252 13.5C4.08252 13.3892 4.12652 13.283 4.20484 13.2047L12.4806 4.92188H6.18765C6.07577 4.92188 5.96846 4.87743 5.88934 4.79831C5.81023 4.71919 5.76578 4.61189 5.76578 4.5C5.76578 4.38811 5.81023 4.28081 5.88934 4.20169C5.96846 4.12257 6.07577 4.07813 6.18765 4.07812H13.5002C13.612 4.07813 13.7193 4.12257 13.7985 4.20169C13.8776 4.28081 13.922 4.38811 13.922 4.5Z"
                                                        fill="currentColor"
                                                    ></path>
                                                </svg>
                                            </i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- ********************-->
                        <!-- ********************-->
                    </div>
                </div>
            </section>
            <!-- ==================== # ==================== -->

  <!-- /BODY -->

</template>

<script>
import axios from 'axios';

export default {
  name: 'HomePage',
  props: {

  },
  data(){
    return {
        // hostLink : 'http://127.0.0.1:8000/',
        hostLink : 'https://www.admin.alfao.com.sa/',
        //--------------------
        SQLBranches: [],
    }
  },
  created(){
    this.getData();
  },
  methods : {
    //--------------------------
    getData() {
        axios.get(this.hostLink + 'api/ece4724c41ae49a3b7e98e1b97c59d1a/branches')
            .then((response) => {
               //console.log( response.data);
               this.SQLBranches = response.data;
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    },
  }
}
</script>
